import ReactDOM from "react-dom/client";
import { createBrowserRouter } from "react-router-dom";

import HomePage from './pages/HomePage';
import WhoWeArePage from './pages/WhoWeArePage';
import EmployeePage from './pages/EmployeePage';
import WhatWeBelievePage from './pages/WhatWeBelievePage';
import ProgramsAndProductsPage from './pages/ProgramsAndProductsPage';
import PerspectivePage from './pages/PerspectivePage';
import SocialPurposeAnnualReportPage from './pages/SocialPurposeAnnualReportPage';
import SocialPurposeAnnualReport2023Page from './pages/SocialPurposeAnnualReport2023Page';
import ContactPage from './pages/ContactPage';
import AdminMediaPage from './pages/AdminMediaPage';
import ErrorPage from './pages/ErrorPage';

export const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/who-we-are/",
    element: <WhoWeArePage />,
  },
  {
    path: "/who-we-are/:employeeId",
    element: <EmployeePage />,
  },
  {
    path: "/what-we-believe",
    element: <WhatWeBelievePage />,
  },
  {
    path: "/programs-and-products",
    element: <ProgramsAndProductsPage />,
  },
  {
    path: "/perspective",
    element: <PerspectivePage />,
  },
  {
    path: "/social-purpose-annual-report",
    element: <SocialPurposeAnnualReportPage />,
  },
  {
    path: "/social-purpose-annual-report-2023",
    element: <SocialPurposeAnnualReport2023Page />,
  },
  {
    path: "/contact",
    element: <ContactPage />
  },
  {
    path: "/adminMedia",
    element: <AdminMediaPage />
  }
]);
